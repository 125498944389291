import {TableBase} from "../../tables/table_base";

class RestInfringements extends TableBase {
  constructor() {
    super('rest_infringements');
  }

  paintTable($restInfringementSummary, rest_infringements) {
    $restInfringementSummary.empty()
    let hasRows = false

    for (const key in rest_infringements) {
      const row = rest_infringements[key];
      const $row = $('<tr>');
      const $labelCell = $('<td>').text(row.label);
      const $thisYearCell = $('<td>').text(row.this_year);
      const $lastYearCell = $('<td>').text(row.last_year);
      const $changeCell = $('<td>').text(`${row.percent_change} %`)
        .addClass(row.percent_change > 0 ? 'red': 'green');

      $row.append($labelCell, $thisYearCell, $lastYearCell, $changeCell);
      $restInfringementSummary.append($row);

      hasRows = true
    }

    if( !hasRows ){
      $restInfringementSummary.html('<tr><td colspan="5">No rest infringement summary to display</td></tr>');
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new RestInfringements();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
